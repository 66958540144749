import * as React from "react";
import { TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Stack, FormHelperText, Select, InputLabel, MenuItem } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SimpleReactValidator from "simple-react-validator";
import { Loader } from '../../../shared';

export const EditBookingModal = (props) => {
    const { state, handleEditBooking, handleInput } = props
    const { bookingDate, timeType, userMobile, userName, status } = state.updateDate
    const [, forceUpdate] = React.useState();
    const validator = React.useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate: forceUpdate },
        })
    );
    console.log(timeType);

    const onSubmit = () => {
        if (validator.current.allValid()) {
            handleEditBooking()
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    };


    const statusOptions = [
        {
            label: "Booked",
            value: "booked",
        },
        {
            label: "Confirmed",
            value: "confirmed",
        },
        {
            label: "Completed",
            value: "completed",
        },
    ]

    return (
        <Stack gap="16px" pt="24px" justifyContent="space-between" height="100%">
            <Stack gap="16px">
                <TextField
                    name="userName"
                    label="Name"
                    variant="outlined"
                    disabled
                    value={userName}
                    onChange={handleInput}

                />

                <TextField
                    name="userMobile"
                    label="Phone Number"
                    variant="outlined"
                    type="number"
                    value={userMobile}
                    onChange={handleInput}
                    disabled

                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Booking Date"
                        inputFormat="DD-MM-YYYY"
                        value={bookingDate}
                        disablePast
                        disabled
                        onChange={({ $d }) =>
                            handleInput({
                                target: {
                                    name: "bookingDate",
                                    value: $d
                                },
                            })
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                
                            />}
                    />

                </LocalizationProvider>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        type="select"
                        name="status"
                        label="Status"
                        width="200px"
                        onChange={(e, v) => handleInput(e, v, "status")}
                        value={status}
                    >
                        {statusOptions.map((data, i) => (
                            <MenuItem value={data.value} key={i}>{data.label}</MenuItem>

                        ))}
                    </Select>
                </FormControl>
                {bookingDate && (
                    <FormControl >
                        <FormLabel onClick={() => console.log(timeType)} id="demo-radio-buttons-group-label">Time</FormLabel >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            // value={timeType}
                            defaultValue={timeType.toString()}
                            onChange={(e, v) =>
                                handleInput({
                                    target: {
                                        name: "timeType",
                                        value: v
                                    },
                                })
                            }
                        >
                            <FormControlLabel value="morning" control={<Radio disabled />} label="Morning " />
                            <FormControlLabel value="evening" control={<Radio disabled />} label="Evening " />
                            <FormControlLabel value="fullday" control={<Radio disabled />} label="Full Day" />
                        </RadioGroup>
                    </FormControl>
                )}

            </Stack>
            <Button variant="contained" onClick={onSubmit}>Update</Button>
        </Stack>
    );
};
