import React from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdminProtectedRoute, Theme, UnAuthorised } from "../shared";
import { Dashboard, StoreAdminDashboard } from "./containers";
import { Authentication, Invitation } from "../modules";
import { AuthProvider } from "../store";

export function Master() {
  return (
    <>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <AuthProvider>
          <Routes>
            <Route element={<Authentication />} path={"/authentication/*"} />
            <Route element={<AdminProtectedRoute allowedRole="admin" />}>
              <Route exact element={<Dashboard />} path={"/*"} />
            </Route>
            <Route
              exact
              element={<UnAuthorised />}
              path={"/unauthorized-1"}
            />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}
