import { useState, useRef } from "react";
import { Box, Button, Card, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ContentLayout } from "../../../shared";
import { Security, ProfileData } from "../components";
import { useProfile } from "..";
import SimpleReactValidator from "simple-react-validator";
import InputControl from "../../../shared/components/InputControl";

export function ProfileDetails() {
  const { state, onChangePass, handleChangePassWord } = useProfile()
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const handleChange = (event, newValue) => {
  };

  return (
    <>
      <ContentLayout title="Change Password" backText="Booking" backButtonLocation={
        "/"
      }>
        <Card sx={{
          p: "24px",
          maxWidth: "500px"
        }}>

          <InputControl
            label="New Password"
            name="newPassword"
            placeholder="New Password"
            onChange={onChangePass}
            value={state.updateData.newPassword}
          />
          <Button variant="contained" sx={{
            mt: "16px",
            float: "right"
          }}
            disabled={state.isBusy}
            onClick={handleChangePassWord}
          >
            Submit
          </Button>
        </Card>
      </ContentLayout>
    </>
  );
}
