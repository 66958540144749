import { Routes, Route } from "react-router-dom";
import {
  DashboardLayout,
  UnAuthorised,
} from "../../shared";

import {
  Profile,
  BFITDashboard,
} from "../../modules";


export function Dashboard() {
  return (
    <div id="app">
      <DashboardLayout>
        <div id="main-content">
          <Routes>
            <Route exact element={<BFITDashboard />} path={"/*"} />
            <Route exact element={<Profile />} path={"/profile/*"} />
            <Route exact element={<UnAuthorised />} path={"/unauthorized"} />
          </Routes>
        </div>
      </DashboardLayout>
    </div>
  );
}
