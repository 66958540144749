import { API } from "../../../utils";

export const getAllListBooking = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(`booking?offset=${pageIndex}&limit=${pageSize}`, query);
  return res.data;
};

export const deleteBookingById = async (id) => {
  const res = await API.post(`booking/delete/${id}`);
  return res.data;
};

export const createBooking = async (payload) => {
  const res = await API.post(`booking/book`, payload);
  return res.data;
};

export const checkBookingDate = async (date) => {
  const res = await API.post(`booking/check-date?bookingDate=${date}`,);
  return res.data;
};

export const updateBooking = async (id, payload) => {
  const res = await API.post(`booking/edit/${id}`, payload);
  return res.data;
};