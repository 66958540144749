import React from "react";
import { ContentLayout, CustomeDialog, DeleteModal, Loader, ServerPaginatedTable } from "../../../shared";
import { useDashboard, AddBookingModal, EditBookingModal } from "..";
import InputControl from "../../../shared/components/InputControl";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from 'react-device-detect';

export const DashboardUi = () => {
    const { state,
        setPageSize,
        gotoPage,
        handleFilter,
        resetFilters,
        handleSearch,
        onSeachClear,
        handleModal,
        handleDeletBooking,
        handleCreateBooking,
        handleInput,
        handleEditBooking,
    } = useDashboard({ isList: true })

    const { isLoading, dataList, pagination, filterApplied, isOpenDelete, isOpenAdd, isDisabled } = state

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "userName",
            },
            {
                Header: "Phone Number",
                accessor: "userMobile",
            },

            {
                Header: "Booking Date",
                accessor: "bookingDate",
                color: "rgba(0, 0, 0, 0.03)",
            },
            {
                Header: "Booking Time",
                accessor: "timeType",
                color: "rgba(0, 0, 0, 0.03)",
            },
            {
                Header: "status",
                accessor: "status",
            },

            {
                Header: "Action",
                id: "action",
                align: "right",
                disableSortBy: true,
                accessor: (row, index) => {
                    return (

                        <div className="action-buttons">
                            <img alt="delete"
                                onClick={() => handleModal("book-delete", true, row._id)}
                                src="/icons/ic_delete.svg"
                            />
                            <img
                                alt="edit"
                                src="/icons/ic_edit.svg"
                                onClick={() => handleModal("booking-edit", true, row._id, row)}
                            />
                        </div>
                    );
                },
            },
        ],
        [dataList]
    );

    const statusOptions = [
        {
            label: "Booked",
            value: "booked",
        },
        {
            label: "Confirmed",
            value: "confirmed",
        },
        {
            label: "Completed",
            value: "completed",
        },
    ]

    return (
        <ContentLayout title="Booking"

        >
            {isLoading ?
                <Loader />
                :
                (
                    <ServerPaginatedTable
                        columns={columns}
                        data={dataList}
                        defaultSearch={false}
                        gotoPage={gotoPage}
                        setPageSize={setPageSize}
                        pagination={pagination}
                        filterContent={
                            <>
                                <InputControl
                                    type="table-search"
                                    value={filterApplied.searchText}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onClear={onSeachClear}
                                />
                                <Button variant="contained" onClick={() => handleModal("booking-add", true)} >Add</Button>
                            </>
                        }
                        filterSection={
                            <>
                                <InputControl
                                    type="datePicker"
                                    name="settlement_date"
                                    label="Start Date"
                                    inputFormat="dd-MM-yyyy"
                                    disableFuture
                                    sx={{
                                        minWidth: "158px",
                                        maxWidth: "200px"
                                    }}
                                    onChange={(e, v) => handleFilter(e, v, "sdate")}
                                    value={filterApplied.bookingDateStart}
                                />
                                <InputControl
                                    type="datePicker"
                                    name="settlement_date"
                                    label="End Date"
                                    inputFormat="dd-MM-yyyy"
                                    disableFuture
                                    sx={{
                                        minWidth: "158px",
                                        maxWidth: "200px"
                                    }}
                                    onChange={(e, v) => handleFilter(e, v, "edate")}
                                    value={filterApplied.bookingDateEnd}

                                />
                                <InputControl
                                    type="select"
                                    name="status"
                                    label="Status"
                                    optionValue={statusOptions}
                                    width="200px"
                                    onChange={(e, v) => handleFilter(e, v, "status")}
                                    value={filterApplied.status}
                                />
                                <Button
                                    type="button"
                                    size="medium"
                                    variant="text"
                                    color="gray"
                                    sx={{
                                        minWidth: "40px",
                                        p: "8px",
                                        ml: "auto",
                                    }}
                                    onClick={() => resetFilters()}
                                    disabled={isDisabled}
                                >
                                    <CloseIcon />
                                </Button>
                            </>
                        }
                    />
                )}
            <DeleteModal
                open={isOpenDelete}
                handleClose={() => handleModal("book-delete", false)}
                onConfirm={handleDeletBooking}
            />
            <CustomeDialog
                open={isOpenAdd}
                handleClose={() => handleModal("booking-add", false)}
                PaperProps={{
                    sx: { width: isMobile ? "100%" : "423px", height: isMobile ? "100%" : "546px", p: "16px" },
                }}
                title="Add Booking"
                content={
                    <AddBookingModal
                        state={state}
                        handleCreateBooking={handleCreateBooking}
                        handleInput={handleInput}
                    />
                }
            />
            <CustomeDialog
                open={isOpenAdd}
                handleClose={() => handleModal("booking-edit", false, null, null)}
                PaperProps={{
                    sx: { width: isMobile ? "100%" : "423px", height: isMobile ? "100%" : "546px", p: "16px" },
                }}
                title="Edit Booking"
                content={
                    <EditBookingModal
                        state={state}
                        handleEditBooking={handleEditBooking}
                        handleInput={handleInput}
                    />
                }
            />
        </ContentLayout >

    );
};
