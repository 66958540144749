import axios from "axios";
import { BASE_URL } from "./apiConfig";
import { getToken, getStoreId } from "./helper";

export const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    } else {
      config.headers["Authorization"] = null;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/authentication/login";
    }
    return Promise.reject(error);
  }
);
