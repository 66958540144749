import * as React from "react";
import { TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Stack, FormHelperText } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SimpleReactValidator from "simple-react-validator";
import { Loader } from '../../../shared';

export const AddBookingModal = (props) => {
    const { state, handleCreateBooking, handleInput } = props
    const { bookingDate, timeType, userMobile, userName } = state.updateDate
    const { evening, fullday, morning } = state.timeSlotes
    const [, forceUpdate] = React.useState();
    const validator = React.useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate: forceUpdate },
        })
    );


    const onSubmit = () => {
        if (validator.current.allValid()) {
            handleCreateBooking()
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    };

    return (
        <Stack gap="16px"  pt="24px" justifyContent="space-between" height="100%">
            <Stack gap="16px">
            <TextField
                name="userName"
                label="Name"
                variant="outlined"
                
                value={userName}
                onChange={handleInput}
                error={validator.current.message("Name", userName, "required")}
                helperText={validator.current.message(" Name", userName, "required")}
            />

            <TextField
                name="userMobile"
                label="Phone Number"
                variant="outlined"
                type="number"
                value={userMobile}
                onChange={handleInput}
                error={validator.current.message("Phone Number", userMobile, "required|phone")}
                helperText={validator.current.message("Phone Number", userMobile, "required|phone")}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Booking Date"
                    inputFormat="DD-MM-YYYY"
                    value={bookingDate}
                    disablePast
                    onChange={({ $d }) =>
                        handleInput({
                            target: {
                                name: "bookingDate",
                                value: $d
                            },
                        })
                    }
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            error={validator.current.message("Booking Date", bookingDate, "required")}
                            helperText={validator.current.message("Booking Date", bookingDate, "required")}
                        />}
                />

            </LocalizationProvider>
            {state.timeSlotesLoader ? <Loader /> : (
                <>
                    {bookingDate && (
                        <FormControl >
                            <FormLabel id="demo-radio-buttons-group-label">Time</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="radio-buttons-group"
                                value={timeType}
                                onChange={(e, v) =>
                                    handleInput({
                                        target: {
                                            name: "timeType",
                                            value: v
                                        },
                                    })
                                }
                            >
                                <FormControlLabel value="morning" control={<Radio disabled={!morning}  />} label="Morning " />
                                <FormControlLabel value="evening" control={<Radio disabled={!evening}  />} label="Evening " />
                                <FormControlLabel value="fullday" control={<Radio disabled={!fullday}  />} label="Full Day" />
                            </RadioGroup>
                            <FormHelperText sx={{ color: '#C60808' }}>{validator.current.message("Time slot", timeType, "required")}</FormHelperText>
                        </FormControl>
                    )}
                </>
            )}
            </Stack>
            <Button variant="contained" onClick={onSubmit}>ADD</Button>
        </Stack>
    );
};
