import React from "react";
import { Route, Routes } from "react-router-dom";
import { NavBar } from "../../shared";
import { ChangePassword } from "../Profile";
import { Login, ResetPassword } from "./containers";

export const Authentication = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
      </Routes>
    </>
  );
};
