import React from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardUi } from "..";


export const BFITDashboard = () => {
    console.log("dash");
    return (
        <>
            <Routes>
                <Route path="/" element={<DashboardUi />} />
            </Routes>
        </>
    );
};
