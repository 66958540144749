import { Box, Link, Stack, Typography, Chip, Avatar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export const ContentLayout = (props) => {
  const {
    children,
    title,
    backText,
    backImage = false,
    active = false,
    rightContent = null,
    breadcrumbs = null,
    backButtonLocation = -1,
    chipType = "status",
    label,
    labelColor,
    statusContent = null,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          px: "24px !important",
        }}
      >
        <Stack
          sx={{
            pt: "32px",
            pb: "24px",
            gap: "20px",
          }}
        >
          {backText && (
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => {
                  navigate(backButtonLocation);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  height: "20px",
                  color: "#000000",
                  fontWeight: "500",
                  // mb: "20px",
                  "&:hover": {
                    color: "#0B57D0",
                  },
                }}
              >
                <ArrowBackIcon sx={{ height: "20px" }} />
                {backText}
              </Link>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
                // height: "40px",
              }}
            >
              {backImage && (
                <Box
                  height={"80px"}
                  width={"80px"}
                  sx={{
                    mr: "20px",
                    borderRadius: "12px",
                  }}
                >
                  {typeof backImage === "string" ? (
                    <Avatar
                      sx={{
                        height: "80px",
                        width: "80px",
                        backgroundColor: "transparent",
                      }}
                      variant="rounded"
                      src={backImage}
                    />
                  ) : (
                    backImage
                  )}
                </Box>
              )}
              <Stack gap="10px">
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Typography
                    variant="h5"
                    mr={1}
                    sx={{
                      lineHeight: "30px",
                    }}
                  >
                    {title}
                  </Typography>
                  {chipType === "status" ? (
                    active && (
                      <Chip
                        label={active}
                        color={"success"}
                        variant="outlined"
                        size="small"
                        disabled={active === "Active" ? false : "filled"}

                      />
                    )
                  ) : chipType === "salesStatus" ? (
                    <Chip
                      label={label}
                      color={labelColor}
                      variant={"outlined"}
                      size="small"
                    />
                  ) : chipType === "other" ?
                    statusContent : null}
                </Stack>

                {breadcrumbs}
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "24px",
                height: "40px",
              }}
            >
              {rightContent}
              {/* {topContent} */}
            </Stack>
          </Box>
        </Stack>

        {children}
      </Box>
    </>
  );
};
