import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../store/AuthProvider";

export const AdminProtectedRoute = ({ allowedRole }) => {
  const { auth } = useContext(AuthContext);

  const isAllowed = auth?.data?.roles?.find(
    (item) => item.name === allowedRole
  );

  return (
    !auth.isInitialLoad &&
    (auth?.data?.accessToken ? (
      <Outlet />
    ) : (
      <Navigate to="/authentication/login" replace />
    ))
  );
};
