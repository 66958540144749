import * as React from "react";
import {
    Box,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useLocation, Link } from "react-router-dom";

export const SidebarMenu = (props) => {
    const { item, index } = props;
    const { pathname } = useLocation();
    const isStoreAdmin = pathname.includes("store-admin");
    const basepath = isStoreAdmin
        ? "/" + pathname.split("/")[2]
        : "/" + pathname.split("/")[1];
 
    return (
        <>
            <Box
                key={index}
                sx={{
                    alignItems: "center",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    py: "8px",
                }}
                className={`${basepath === item.href ? "sidebar-link-active" : ""}`}
            >
                <Link
                    to={item.href}
                    className="sidebar-link"
                >
                    <ListItemButton
                        key={item.title}
                        sx={{ py: 0, minHeight: 32, color: "#CCCCCC" }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>

                        <ListItemText>{item.title}</ListItemText>
                    </ListItemButton>
                </Link>
            </Box>

        </>
    );
};
