import { useEffect } from "react";
import { useImmer } from "use-immer";

export const useAuth = () => {
  const [auth, setAuth] = useImmer({
    isInitialLoad: true,
    data: {
      user: {
        mobile: "",
        _id: "",
        uvId: "",
        userType: ""
      },
      accessToken: ""
    },
  });
  useEffect(() => {
    setAuth((draft) => {
      draft.isInitialLoad = false;
    });
    const localStorageData =
      JSON.parse(localStorage.getItem("user-data"));
    if (localStorageData !== null) {
      setAuth((draft) => {
        draft.data = localStorageData;
      });
    }
  }, []);

  useEffect(() => {
    if (auth.data.accessToken) {
      localStorage.setItem("user-data", JSON.stringify(auth.data))
    }
  }, [auth]);

  return { auth, setAuth };
};
