import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Typography, Avatar, Stack } from "@mui/material";
import { isMobile } from 'react-device-detect';

export const CustomeDialog = (props) => {
  const {
    open,
    handleClose,
    content = null,
    PaperProps,
    title,
    size = "sm",
  } = props;

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={handleClose}
      PaperProps={PaperProps}
      sx={{
        maxHeight: "100%",
        "& + .pac-container": {
          zIndex: 18000,
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={600} variant="body1">
          {title}
        </Typography>
        {CloseIcon && (
          <Avatar
            sx={{
              bgcolor: " rgba(0, 0, 0, 0.03)",
              position: "absolute",
              top: size === "md" ? "11px" : "6px",
              right: size === "md" ? "13px" : "8px",
              cursor: "pointer",
              width: size === "md" ? 46 : 40,
              height: size === "md" ? 46 : 40,
              "&:hover": {
                bgcolor: "rgba(0,0,0, 0.08)",
              },
            }}
            variant="rounded"
            onClick={handleClose}
          >
            <CloseIcon
              sx={{
                color: "#000",
              }}
            />
          </Avatar>
        )}
      </Stack>

      {content}
    </Dialog>
  );
};
