import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  OutlinedInput,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import AccountPopover from "./AccountPopover";
import InputControl from "./InputControl";
import { useContext } from "react";
import { AuthContext } from "../../store/AuthProvider";
import { useLocation } from "react-router-dom";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

export const DashboardNavbar = (props) => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { onSidebarOpen, ...other } = props;
  const {
    auth: {
      data: { stores, selectedStoreId },
    },
    setAuth,
  } = useContext(AuthContext);

  // const storeList = stores.map((item) => ({
  //   label: item.name,
  //   value: item.id,
  // }));
  // const onStoreChange = (e) => {
  //   const { value } = e;
  //   setAuth((draft) => {
  //     draft.data.selectedStoreId = value;
  //   });
  //   window.location.reload();
  // };

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: "calc(100% - 280px)",
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>

          
          <Box sx={{ flexGrow: 1 }} />
          
          {/* {isStoreAdmin && (
            <InputControl
              type="dropdown"
              style={{ width: 183 }}
              options={storeList || []}
              disableClearable
              value={
                (storeList?.length > 0 &&
                  selectedStoreId &&
                  storeList.find((item) => item.value === selectedStoreId)) ||
                null
              }
              includeInputInList
              onChange={(e, value) => onStoreChange(value)}
              filterSelectedOptions
              sx={{
                "& input": { px: "0px !important" },
                "& fieldset": { border: "none" },
              }}
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      alt="store icon"
                      loading="lazy"
                      width="20"
                      src="/icons/ic_store.svg"
                    />
                    {option.label}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    borderRadius: "8px",
                    ".MuiInputBase-root": { height: "44px" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="store icon" src="/icons/ic_store.svg" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )} */}

          <IconButton sx={{ ml: 1 }}>
            <AccountPopover />
          </IconButton>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
