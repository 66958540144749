import React from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InputControl from "./InputControl";

const tableCellStyle = {
  borderBottom: "1px solid #E6E6E6",
  borderRight: "1px solid #E6E6E6",
  padding: "12px 10px",
  width: "191px !important",
};
const data = [
  { denomination: 5, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 10, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 20, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 50, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 1, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 2, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 5, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 10, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 20, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 50, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
  { denomination: 100, float: 0, floatTotal: 0, cash: 0, cashTotal: 0 },
];
export const EndofDayTable = () => {
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: "1px solid #E6E6E6" }}>
                Denomination
              </TableCell>
              <TableCell align="left">Float</TableCell>
              <TableCell
                align="left"
                sx={{ borderRight: "1px solid #E6E6E6" }}
              ></TableCell>
              <TableCell align="left">Cash</TableCell>
              <TableCell align="leftt"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              const { denomination, float, floatTotal, cash, cashTotal } = item;
              return (
                <TableRow>
                  <TableCell align="left" sx={{ ...tableCellStyle }}>
                    <Typography
                      fontWeight={600}
                      fontSize={16}
                      sx={{
                        lineHeight: "19px",
                      }}
                    >
                      ${denomination}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableCellStyle,
                      backgroundColor: "rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <InputControl name="name" value={float} required />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...tableCellStyle,
                      backgroundColor: "rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <Stack direction="row">
                      <Typography
                        fontWeight={600}
                        sx={{
                          lineHeight: "17px",
                        }}
                      >
                        = ${floatTotal}
                      </Typography>
                      <Typography
                        fontSize={13}
                        marginLeft={1}
                        color="#808080"
                        sx={{
                          lineHeight: "17px",
                        }}
                      >
                        (Need 14)
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ ...tableCellStyle }}>
                    <InputControl
                      name="name"
                      value={cash}
                      //   onChange={onChange}
                      required
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ ...tableCellStyle }}>
                    <Typography
                      fontWeight={600}
                      sx={{
                        lineHeight: "22px",
                      }}
                    >
                      = ${cashTotal}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                align="left"
                sx={{ ...tableCellStyle, height: "68px" }}
              >
                <Typography
                  fontWeight={600}
                  sx={{
                    lineHeight: "22px",
                  }}
                >
                  Need : $0.00
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  minWidth: "191px",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                  height: "68px",
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize={16}
                  sx={{
                    lineHeight: "19px",
                  }}
                >
                  Float Total
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  ...tableCellStyle,

                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                  height: "68px",
                }}
              >
                <Typography
                  fontWeight={600}
                  sx={{
                    lineHeight: "22px",
                  }}
                >
                  = $350
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ ...tableCellStyle, height: "68px" }}
              >
                <Typography
                  fontWeight={600}
                  sx={{
                    lineHeight: "22px",
                  }}
                >
                  Cash total
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ ...tableCellStyle, height: "68px" }}
              >
                <Typography
                  fontWeight={600}
                  sx={{
                    lineHeight: "22px",
                  }}
                >
                  = $250
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
