import parse from "html-react-parser";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export const getToken = () => {
  let token = "";
  var localData = JSON.parse(localStorage.getItem("user-data"));
  token = localData?.accessToken;
  return token;
};

export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/success_toast.png",
    style: {
      background: "#388e3c",
    },
  }).showToast();
};

export const infoMessage = async (text) => {
  if (text === undefined || text === "") return;
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#388e3c",
    },
    selector: "toastMessage",
  }).showToast();
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#d32f2f",
    },
  }).showToast();
};

export const sortItems = (prev, curr, columnId) => {
  if (
    prev.original[columnId]?.toLowerCase() >
    curr.original[columnId]?.toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId]?.toLowerCase() <
    curr.original[columnId]?.toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

const ErrorFormatter = (error) => {
  let errorString = "";

  if (error.response) {
    if (error.response.data.message) {
      errorString += error.response.data.message + "</br>";
    }
    if (error.response.data.errors !== null) {
      for (var err in error.response.data.errors) {
        errorString += error.response.data.errors[err];
      }
    } else if (!error.response.data.message) {
      errorString += "An error has occured!";
    }
  } else {
    errorString += "An error has occured!";
  }
  return parse(errorString);
};
