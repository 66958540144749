import styled from "@emotion/styled";
import { AppBar, Box } from "@mui/material";
import LogoBlack from "../../assets/images/Logo/Logo3.png";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  minHeight: 64,
}));

export const NavBar = (props) => {
  const { onSidebarOpen, ...other } = props;

  return (
    <>
      <DashboardNavbarRoot {...other}>
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "8vh",
          }}
        >
          <img src={LogoBlack} alt="logo" style={{
            height: "36px"
          }} />
        </Box>

        <Box sx={{ flexGrow: 1 }} />
      </DashboardNavbarRoot>
    </>
  );
};
