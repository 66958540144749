import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import { checkBookingDate, createBooking, deleteBookingById, getAllListBooking, updateBooking } from "../api";
import { addDays } from 'date-fns'
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import qs from "qs";

export const useDashboard = ({ isList = false }) => {
    const { pathname } = useLocation();
    const isStoreAdmin = pathname.includes("store-admin");

    const [state, setState] = useImmer({
        isLoading: true,
        dataList: [],
        isOpenAdd: false,
        isOpenDelete: false,
        selectedId: null,
        isDisabled: true,
        filterApplied: {
            status: null,
            bookingDateStart: null,
            bookingDateEnd: null,
            searchText: null,
        },
        pagination: {
            pageIndex: 1,
            pageSize: 10,
            pageCount: 2,
            total: 0,
            hasMorePages: true,
            lastPage: 1,
        },
        updateDate: {
            userMobile: "",
            userName: "",
            bookingDate: null,
            timeType: ""
        },
        timeSlotesLoader: false,
        timeSlotes: {
            evening: true,
            fullday: true,
            morning: true
        }
    });

    const [debouncedText] = useDebounce(state.filterApplied.searchText, 1500);

    useEffect(() => {
        if (isList && state.isLoading) {
            getBookingList()
        }
    }, [isList, state.isLoading])

    useEffect(() => {
        if (state.filterApplied.searchText !== null) {
            setState((draft) => {
                draft.isLoading = true;
            });
        }
    }, [debouncedText]);

    useEffect(() => {
        if (state.updateDate.bookingDate) {
            handleCheckDate(state.updateDate.bookingDate)
        }
    }, [state.updateDate.bookingDate])

    useEffect(() => {
        const { bookingDateEnd, bookingDateStart, searchText, status } = state.filterApplied
        if (status || bookingDateEnd || bookingDateStart || searchText) {
            setState((draft) => {
                draft.isDisabled = false;
            });
        } else {
            setState((draft) => {
                draft.isDisabled = true;
            });
        }
    }, [state.filterApplied])


    const getBookingList = async () => {
        try {
            let params = {};

            if (state.filterApplied.searchText) {
                params = { ...params, search: state.filterApplied.searchText };
            }
            if (state.filterApplied.status) {
                params = { ...params, status: state.filterApplied.status };
            }
            if (state.filterApplied.bookingDateStart) {
                params = { ...params, bookingDateStart: state.filterApplied.bookingDateStart };
            }
            if (state.filterApplied.bookingDateEnd) {
                params = { ...params, bookingDateEnd: state.filterApplied.bookingDateEnd };
            }

            const query = {
                params,
                paramsSerializer: (params) => qs.stringify(params, { encode: true }),
            };

            const res = await getAllListBooking(query, state.pagination);
            if (res.success) {

                setState((draft) => {
                    draft.dataList = res.data.bookings;
                    draft.pagination.hasMorePages = draft.pagination.lastPage === draft.pagination.pageIndex ? false : true
                    draft.pagination.lastPage = Math.ceil(res.data.count / state.pagination.pageSize)
                    draft.pagination.total = res.data.count;
                    draft.isLoading = false;
                });
            }
        } catch (error) {
            setState((draft) => {
                draft.isLoading = false;
            });
        }
    };

    const setPageSize = (e) => {

        setState((draft) => {
            draft.pagination.pageSize = +e.target.value;
            draft.isLoading = true
        });
    };


    const gotoPage = (page) => {
        setState((draft) => {
            draft.pagination.pageIndex = page;
            draft.isLoading = true
        });
    };

    const handleFilter = (e, value, type) => {
        console.log(e, value, type);
        if (type === "sdate") {
            setState((draft) => {
                draft.filterApplied.bookingDateStart = moment(e).format("yyy-MM-DD")
                draft.pagination.pageIndex = 1;
                draft.isLoading = true
            });
        } else if (type === "edate") {
            setState((draft) => {
                draft.filterApplied.bookingDateEnd = moment(e).format("yyy-MM-DD")
                draft.pagination.pageIndex = 1;
                draft.isLoading = true
            });
        } else {
            setState((draft) => {
                draft.filterApplied.status = e.target.value
                draft.pagination.pageIndex = 1;
                draft.isLoading = true
            });
        }
    };

    const resetFilters = () => {
        setState((draft) => {
            draft.filterApplied = {
                status: null,
                bookingDateStart: null,
                bookingDateEnd: null,
                searchText: null,
            }
            draft.isLoading = true
        });
    }

    const handleSearch = (v) => {
        setState((draft) => {
            draft.filterApplied.searchText = v;
        });
    };

    const onSeachClear = () => {
        setState((draft) => {
            draft.filterApplied.searchText = "";
        });
    };

    const handleModal = (type, states, data, data2) => {
        console.log(type, state, data);
        switch (type) {

            case "book-delete":
                setState((draft) => {
                    draft.isOpenDelete = states;
                    draft.selectedId = data;
                });
                break;
            case "booking-add":
                if (!state) {
                    setState((draft) => {
                        draft.isOpenAdd = states;
                    })
                } else {
                    setState((draft) => {
                        draft.isOpenAdd = states;
                        draft.updateDate = {
                            userMobile: "",
                            userName: "",
                            bookingDate: null,
                            timeType: ""
                        }
                    })
                }

                break;
            case "booking-edit":
                console.log(data2);
                if (!states) {
                    setState((draft) => {
                        draft.isOpenAdd = states;
                        draft.selectedId = data;
                    })
                } else {
                    setState((draft) => {
                        draft.isOpenAdd = states;
                        draft.selectedId = data;
                        draft.updateDate = {
                            userMobile: data2.userMobile,
                            userName: data2.userName,
                            bookingDate: data2.bookingDate,
                            timeType: data2.timeType,
                            status: data2.status
                        }
                    })
                }

                break;

        }
    };



    const handleDeletBooking = async () => {
        try {
            const res = await deleteBookingById(state.selectedId);
            if (res.success) {
                successMessage("Deleted Successfully");
                handleModal("book-delete", false, null);
                setState((draft) => {
                    draft.isLoading = true;
                });
            }
        } catch (err) {
            errorMessage(err.response.data.message);
        }
    };

    const handleCreateBooking = async () => {
        try {
            const res = await createBooking(state.updateDate);
            if (res.success) {
                setState((draft) => {
                    draft.isOpen = false;
                    draft.isLoading = true;
                });
                successMessage("Your booking has been submitted")
                handleModal("booking-add", false)
            }
        } catch (error) {
        }
    };


    const handleEditBooking = async () => {
        try {
            const res = await updateBooking(state.selectedId, state.updateDate);
            if (res.success) {
                setState((draft) => {
                    draft.isOpen = false;
                    draft.isLoading = true;
                });
                successMessage("Your booking has been submitted")
                handleModal("booking-edit", false, null, null)
            }
        } catch (error) {
            errorMessage("error")
        }
    };

    const handleInput = (event) => {
        const { name, value } = event.target
        console.log(name, value);

        if (name === "bookingDate") {
            setState((draft) => {
                draft.updateDate.bookingDate = moment(value).format("YYYY-MM-DD")
            });
        } else {
            setState((draft) => {
                draft.updateDate = {
                    ...draft.updateDate,
                    [name]: value,
                };
            });
        }
    }

    const handleCheckDate = async (date) => {
        try {
            setState((draft) => {
                draft.timeSlotesLoader = true;
                draft.updateDate.timeType = ''
            });
            const res = await checkBookingDate(date);
            if (res.success) {
                setState((draft) => {
                    draft.timeSlotes = res.data;
                    draft.timeSlotesLoader = false;
                });
            }
        } catch (error) {
            setState((draft) => {
                draft.timeSlotesLoader = false;
            });
        }
    };


    return {
        state,
        setPageSize,
        gotoPage,
        handleFilter,
        resetFilters,
        handleSearch,
        onSeachClear,
        handleModal,
        handleDeletBooking,
        handleCreateBooking,
        handleInput,
        handleEditBooking,
    };
};
