import { Icon } from "@iconify/react";
import React, { useContext, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Box, MenuItem, Avatar } from "@mui/material";
import MenuPopover from "./MenuPropover";
import { errorMessage } from "../../utils";
import { API } from "../../utils";
import { AuthContext } from "../../store/AuthProvider";
import KeyIcon from '@mui/icons-material/Key';

export default function AccountPopover() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const anchorRef = useRef(null);
  const { auth } = useContext(AuthContext);

  const isSuperAdminPermission = auth?.data?.roles?.find(
    (item) => item.name === "admin"
  );

  const isStoreAdminPermission = auth?.data?.roles?.find(
    (item) => item.name === "store-admin"
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onLogout = async () => {
    const res = await API.post(`user/logout`);
    return res.data;
  };

  const handleLogout = async () => {
    try {
      const res = await onLogout();
      if (res.success) {
        localStorage.clear();
        window.location.pathname = "/authentication/login";
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const navigateDependingOnUserProfile = () => {
    handleClose();
    navigate("/profile");
  };

  return (
    <>
      <Avatar cursor="pointer" ref={anchorRef} onClick={handleOpen} />
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 292,
          py: "8px",
          boxShadow: "0px 2px 7px rgb(0 0 0 / 25%)",
        }}
      >
        <MenuItem
          key={1}
          onClick={() => navigateDependingOnUserProfile()}
          sx={{
            typography: "body2",
            py: 1,
            px: "16px",
            height: "48px",
            gap: "16px",
          }}
        >
          <KeyIcon />
          Change Password
        </MenuItem>
        <Box sx={{ p: "6px 16px" }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
            sx={{
              p: "4.75px 20px",
            }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
