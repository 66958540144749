import React, { useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Checkbox,
  IconButton,
  Chip,
  Button,
  Pagination,
  TableFooter,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";
import { CustomDrawer, Loader } from "..";
import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useImmer } from "use-immer";

export const ServerPaginatedTable = (props) => {
  const [localState, setLocalState] = useImmer({ initialLoad: true });
  const {
    columns,
    data,
    searchBar = true,
    minWidth = 0,
    maxHeight = "",
    filterContent = null,
    tableTitle,
    headerRightContent = null,
    drawerOpen = false,
    drawerOnClose,
    bulkDelete = false,
    onBulkDelete = {},
    filterApplied = [],
    onFilterClear = {},
    onAllFilterClear = {},
    filterSection = null,
    defaultSearch = true,
    completeFeature = true,
    hiddenColumns = [],
    isLoading,
    gotoPage,
    setPageSize,
    pagination,
    handleSort,
    sortByGlobal,
  } = props;
  const { pageIndex, pageSize, pageCount, total, hasMorePages, lastPage } =
    pagination;
  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state,
    state: { sortBy },
    selectedFlatRows,
    rows,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        hiddenColumns,
        sortBy: sortByGlobal,
      },
      getSubRows: (row) => row.permissions || [],
    },

    useFilters,
    useGlobalFilter,
    useExpanded,
    usePagination,
    useRowSelect,
    // ,
    (hooks) => {
      bulkDelete &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                classes={{ root: "custom-checkbox-root" }}
                color="primary"
                sx={{
                  marginLeft: "-10px",
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            padding: "checkbox",
          },
          ...columns,
        ]);
    }
  );

  useEffect(() => {
    setLocalState((draft) => {
      draft.initialLoad = false;
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Paper className={completeFeature && "table-paper-container"}>
          {tableTitle && (
            <CustomTableHeaderSection
              tableTitle={tableTitle}
              headerRightContent={headerRightContent}
            />
          )}
          {searchBar && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              filterContent={filterContent}
              defaultSearch={defaultSearch}
            />
          )}
          {filterApplied.length > 0 && (
            <FilterSection
              data={filterApplied}
              onFilterClear={onFilterClear}
              onAllFilterClear={onAllFilterClear}
            />
          )}
          {filterSection && <FilterBar filterSection={filterSection} />}
          <TableContainer sx={{ minWidth: minWidth, maxHeight: maxHeight }}>
            <Table
              {...getTableProps()}
              sx={{
                borderCollapse: "inherit",
                overflow: "hidden",
              }}
            >
              <TableHead>
                {headerGroups.map((headerGroup, i) => (
                  <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          p: !column.padding && "10px",
                        }}
                        align={column?.align}
                        padding={column.padding || "normal"}
                      >
                        {bulkDelete &&
                        selectedFlatRows.length > 0 &&
                        index >= 1 ? (
                          <>
                            {index > 1 ? (
                              <></>
                            ) : (
                              <Typography
                                variant="subtitle2"
                                color="#1560D4"
                                lineHeight="18px"
                                onClick={() => {
                                  onBulkDelete(selectedFlatRows);
                                }}
                              >
                                Delete
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            {column.canSort ? (
                              <TableSortLabel
                                active={column.isSorted}
                                direction={column.isSortedDesc ? "dsc" : "asc"}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    height: "15px",
                                    width: "15px",
                                  },
                                }}
                              >
                                {column.render("Header")}
                              </TableSortLabel>
                            ) : (
                              <>{column.render("Header")}</>
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              {preGlobalFilteredRows.length > 0 &&
              globalFilteredRows.length > 0 ? (
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow key={i} {...row.getRowProps()}>
                        {row.cells.map((cell, i) => {
                          return (
                            <TableCell
                              key={i}
                              align={cell.column?.align}
                              padding={cell.column.padding || "normal"}
                              sx={{
                                borderBottom: "1px solid #E6E6E6",
                                p: !cell.column.padding && "8px",
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={20}
                      sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                    >
                      <Typography
                        align="center"
                        variant="body2"
                        color={"#666666"}
                      >
                        No data available in table
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      2, 5, 10, 25, 50, 100,
                      // { label: "All", value: -1 },
                    ]}
                    colSpan={0}
                    count={total}
                    rowsPerPage={pageSize}
                    page={pageIndex > 0 ? pageIndex - 1 : 0}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={gotoPage}
                    onRowsPerPageChange={setPageSize}
                    ActionsComponent={(subProps) => (
                      <TablePaginationActions
                        {...subProps}
                        lastPage={lastPage}
                      />
                    )}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <CustomDrawer
        title="Column Option"
        open={drawerOpen}
        onClose={drawerOnClose}
      >
        <Stack p="24px 16px" gap="16px">
          {allColumns.map((column, index) => (
            <>
              {column.id === "selection" ? (
                ""
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="10px"
                  width="auto"
                  key={index}
                >
                  <Box
                    sx={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "8px",
                      p: "14px",
                    }}
                    width="100%"
                  >
                    <Typography
                      lineHeight="19px"
                      color={column.isVisible ? "black" : "GrayText"}
                    >
                      {column.Header}
                    </Typography>
                  </Box>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      column.toggleHidden();
                    }}
                  >
                    {column.isVisible ? (
                      <img alt="eye" src="/icons/ic_eye.svg" />
                    ) : (
                      <img
                        alt="closed eye"
                        src="/icons/ic_close_eye.svg"
                        className="disabled"
                      />
                    )}
                  </IconButton>
                </Stack>
              )}
            </>
          ))}
        </Stack>
      </CustomDrawer>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              selectedFlatRows: selectedFlatRows.map((row) => row.original),
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
};
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterContent,
  defaultSearch,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      {defaultSearch && (
        <OutlinedInput
          sx={{
            height: "48px",
          }}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  setValue("");
                  onChange("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
        />
      )}
      {filterContent}
    </Stack>
  );
}
export default GlobalFilter;

export function CustomTableHeaderSection({ tableTitle, headerRightContent }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "12px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
        height: "84px",
      }}
    >
      <Typography gutterBottom align="center" variant="subtitle1">
        {tableTitle}
      </Typography>
      {headerRightContent}
    </Stack>
  );
}

export function FilterSection({ data, onFilterClear, onAllFilterClear }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",

        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        {data.map((data, index) => (
          <Chip
            key={index}
            label={
              <Typography variant="body2" fontSize="14px">
                {data.type}: {data.label}
              </Typography>
            }
            onDelete={() => onFilterClear(data)}
            sx={{
              backgroundColor: "#E6E6E6",
              height: "30px",
              "& .MuiChip-deleteIcon": {
                color: "#808080",
              },
            }}
          />
        ))}
      </Stack>

      <Button
        color="info"
        sx={{ width: "81px", minWidth: "81px", height: "30px" }}
        onClick={onAllFilterClear}
      >
        Clear All
      </Button>
    </Stack>
  );
}

export function FilterBar({ filterSection }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        py: "20px",
        px: "16px",
        gap: "16px",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      {filterSection}
    </Stack>
  );
}

export function TablePaginationActions(props) {
  const { onPageChange, lastPage, page } = props;
  return (
    <Box sx={{ flexShrink: 0, marginLeft: "20px" }}>
      <Pagination
        count={lastPage}
        page={page + 1}
        shape="rounded"
        showFirstButton
        showLastButton
        onChange={(e, page) => onPageChange(page)}
      />
    </Box>
  );
}
