import { Box, Drawer, useMediaQuery } from "@mui/material";
import Logo from "../../../assets/images/Logo/Logo2.png";
import Dashboard from "../../../assets/images/Icons/Dashboard.png";
import User from "../../../assets/images/Icons/Admin.png";
import Catalog from "../../../assets/images/Icons/Category.png";
import Stores from "../../../assets/images/Icons/Stores.png";
import Customers from "../../../assets/images/Icons/Customers.png";
import Sales from "../../../assets/images/Icons/ic_order.png";
import Promotions from "../../../assets/images/Icons/Promotions.png";
import version from "../../../../package.json";
import React from "react";

import { SidebarMenu } from ".";

const items = [
  {
    href: "/",
    icon: <img alt="icon-Dashboard" alignment="center" src={Dashboard} />,
    title: "Booking",
    storeAdmin: true,
  }
];

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            px: 3,
            py: "18px",
            borderRadius: 1,
          }}
        >
          <img alt="logo" alignment="center" src={Logo} style={{
            height: '36px'
          }} />
        </Box>

        <Box sx={{ px: 2 }}>
          {items.map((item, index) => {
            return <SidebarMenu item={item} index={index} />;
          })}
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
        >
          Version {version.version}
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "#001A52",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "#001A52",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
