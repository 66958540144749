import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { changePassword } from "../api";
import { errorMessage, successMessage } from "../../../utils";

export const useProfile = () => {

  const [state, setState] = useImmer({
    isBusy: false,
    updateData: {
      newPassword: ""
    }

  });

  const onChangePass = (e) => {
    setState((draft) => {
      draft.updateData.newPassword = e.target.value;
    });
  }

  const handleChangePassWord = async () => { 
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      
      const res = await changePassword(state.updateData);
      if (res.success) {
        successMessage("Password Updated Successfully");
        setState((draft) => {
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }

  }

  return {
    state,
    onChangePass,
    handleChangePassWord
  };
};
